module.exports = {
  // TODO: The following flag is invalid and is only used in the test environment
  // this is not something that is supposed to be used for the production
  // environment. This has to be changed into a feature flag that is active.
  VUE_DEV_TOOLS: 'platform.mocktesting.flaga',
  PLATFORM_SECURITY_CATALOG_CONTENT_SECURITY_POLICY:
    'platform.security.catalog-content-security-policy',
  SIDEBAR: 'product.ca.catalog-pages-sidebar',
  PAGE_BUILDER_SIDEBAR: 'product.ca.page-builder.sidebar',
  REQUISITES_REDESIGNED: 'product.ca.catalog-pages-requisites-v2',
  DEPARTMENT_CATALOG_DESCRIPTIONS:
    'product.ca.catalog-department-page-descriptions',
  PROGRAM_PAGE_TAB_LAYOUT: 'product.ca.program-page.tab-layout',
  COURSE_PAGE_TAB_LAYOUT: 'product.ca.course-page.tab-layout',
  PROGRAM_LANDING_PAGE_REDESIGN:
    'product.ca.program-landing-page.single-rows-redesign',
  NO_DEFAULT_IMAGE: 'product.ca.catalog-pages-no-default-images',
  NON_SCROLLABLE_SUB_NAVIGATION: 'product.ca.sub-navigation.non-scrollable',
  CUSTOM_FILTERS: 'product.ca.custom-filters-public-app',
  PAGE_BUILDER_HOME_PAGE: 'product.ca.page-builder.home', // This FF affects both main app and public catalog
  PUBLIC_PAGE_BUILDER_HOME_PAGE: 'product.ca.public.page-builder.home', // This FF only affects catalog
  DEPARTMENT_LANDING_PAGE_ALPHABETICAL_LIST_VIEW:
    'product.ca.pages.departments.alphabetical-list',
  CA_REQUIREMENT_LEVELS: 'product.ca.requirement-levels',
  GLOBAL_SEARCH: 'product.ca.search.global-search',
  DEPARTMENTS_PAGE_TAB_LAYOUT: 'product.ca.department-page.tab-layout',
  CUSTOM_PAGE_CONFIGURABLE_NAVIGATION:
    'product.ca.custom-page.configurable-navigation', // This FF affects both main app and public catalog
  CATALOG_DEPARTMENTS_PAGE_LAYOUT_SETTINGS:
    'product.ca.departments-page-layout-settings', // This FF affects both main app and public catalog
  CONFIGURABLE_RESOURCE_PREVIEW: 'product.ca.configurable-resource-preview',
  SORT_ENTITIES_ALPHABETICLY_BY_DISPLAYED_VALUE:
    'product.ca.sort-entities-alphabetically-by-displayed-value',
  DEGREEMAP_REDESIGNED: 'product.ca.degree-maps.v2',
  HIDE_EMPTY_FIELDS: 'product.ca.hide-empty-fields',
  HIDE_EMPTY_CARDS: 'product.ca.hide-empty-cards',
  DEPARTMENT_PAGE_PDF: 'product.ca.department-page-pdf',
  SHOW_REQUISITES_SUMMED_CREDITS: 'product.ca.show-requisites-summed-credits',
  FONT_LIBRARY: 'product.ca.font-library',
  SECTION_SCHEDULING_REDESIGN: 'product.ca.section-scheduling-redesign',
  HIDE_DEPARTMENTS: 'product.ca.hide-departments',
  TITLE_INTERPOLATIONS: 'product.ca.title-interpolations',
  HIDE_DM_OR_GROUPING: 'product.ca.dm-hide-or-grouping',
  HIDE_FACULTY_IF_NONE: 'product.ca.hide-faculty-if-none',
  DISABLE_DECAMELIZE_FIELDS: 'product.ca.degree-maps-decamelize-behavior',
  NO_INDEX: 'product.ca.no-index',
  LIGHTCAST_WIDGET: 'ca-lightcast-widget',
  PRINT_LIST: 'product.ca.print-list',
  PRINT_DOCUMENTS: 'product.ca.print-documents',
  CM_COURSE_SETS_EFFECTIVE_DATING: 'product.cm.course-sets-effective-dating',
  SM_IMPORT_WITH_RAW_COURSE_ID: 'product.sm.courses.import-with-rawCourseId',
  USE_CATALOG_FILTERS_FOR_REQUISITES: 'products.ca.use-catalog-filters-in-requirements'
}
