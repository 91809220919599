import decam from 'decamelize'

let UniDomParser

if (typeof window !== 'undefined' && window.DOMParser) {
  UniDomParser = window.DOMParser
} else {
  UniDomParser = require('universal-dom-parser')
}

const ASYNC_DATA_QUESTION = ['courseSchedule']

export function debounce(func, delay) {
  let inDebounce
  return function() {
    const context = this
    const args = arguments
    clearTimeout(inDebounce)
    inDebounce = setTimeout(() => func.apply(context, args), delay)
  }
}
export function decamelize(text) {
  if (!text) return ''
  return decam(text)
    .split('_')
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(' ')
}

export function capitalize(string) {
  if (typeof string !== 'string' || !string) return ''
  const regex = /(\b[a-z](?!\b))/g
  return string.replace(regex, (match) => match.toUpperCase())
}

export function parseEscapedHtmlContent(content) {
  if (!content) return ''
  const doc = new UniDomParser().parseFromString(content, 'text/html')
  return doc.documentElement.textContent
}

export function getShortDescription(description) {
  if (!description) return 'No Description Set'
  description = parseEscapedHtmlContent(description)
  if (description.length <= 250) return description

  return description.slice(0, 250).trim() + '...'
}

export function isObject(value) {
  return value !== null && typeof value === 'object' && !Array.isArray(value)
}

export function isEmptyArray(value) {
  return typeof value === 'object' && Object.keys(value).length === 0
}

export function isValueEmpty(val) {
  if (Array.isArray(val)) {
    return isEmptyArray(val) || isArrayContentEmpty(val)
  } else if (isObject(val)) {
    return isArrayContentEmpty(Object.values(val))
  } else {
    return val !== 0 && val !== false && !val
  }
}

export function isArrayContentEmpty(arr) {
  return arr.every((val) => isValueEmpty(val))
}

function anyNestedFieldShouldBeRendered(questionDefinition, value, parentKey) {
  const subfieldsDefinitions = questionDefinition.config?.fields || {}
  const subDefsArray = Object.values(subfieldsDefinitions)
  return subDefsArray.some(
    (subDef) =>
      Array.isArray(value)
        ? value.some((valueItem) =>
            shouldFieldBeRendered(subDef, valueItem, parentKey)
          )
        : shouldFieldBeRendered(subDef, value, parentKey)
  )
}

export function shouldFieldBeRendered(
  questionDefinition,
  documentValue = {},
  nestedParentKey
) {
  if (
    nestedParentKey !== undefined
      ? questionDefinition.hidden
      : questionDefinition.config?.hidden
  )
    return false

  // for questions having their data loaded asynchronously there's no value to be checked
  // so those have to determine whether to render or not, on their own
  if (ASYNC_DATA_QUESTION.includes(questionDefinition.dataKey)) {
    return true
  }

  const hasNestedFields = !!questionDefinition.config?.fields
  const valueSource =
    // NOTE: some custom fields are not stored inside `customFields` object
    //       that's common for `questionWithNestedFields` quesiton type
    (questionDefinition.custom && documentValue.customFields
      ? documentValue.customFields
      : documentValue) || {}

  // some nested fields configurations still use `key` prop
  const key = questionDefinition.dataKey || questionDefinition.key
  let value = valueSource[key]

  if (key === 'dependencies') {
    const courseDependents = valueSource.courseDependents || []
    const programDependents = valueSource.programDependents || []
    value = [...courseDependents, ...programDependents]
  }

  let result
  if (nestedParentKey === 'requisitesSimple') {
    result = !isValueEmpty(documentValue) && !!documentValue?.showInCatalog
  } else if (nestedParentKey === 'concentrations') { // HOTFIX for [CA-16925], need further checking [INT-17014] and discussion https://coursedoginc.slack.com/archives/C04G2AVG2P9/p1719321950576199
    result =
      !isValueEmpty((documentValue||{})[key]) || !isValueEmpty((documentValue?.customFields||{})[key])
  } else if (nestedParentKey === 'requisitesFreeform') {
    result =
      !isValueEmpty(documentValue.value) && !!documentValue?.showInCatalog
  } else {
    result = hasNestedFields
      ? anyNestedFieldShouldBeRendered(questionDefinition, value, key)
      : !isValueEmpty(value)
  }
  return result
}

export function anyCardItemVisible(questionsArray, documentValue) {
  return (itemsTemplate) => {
    const items = itemsTemplate
      .filter((item) => item.type === 'row')
      .reduce((fieldsList, currRow) => {
        const fields = currRow.children
          .filter((item) => item.type === 'question')
          .map((question) => question.id)
        return fieldsList.concat(fields)
      }, [])
    const anyVisible = items
      .map(
        (itemId) =>
          questionsArray.find((question) => question.questionId === itemId) ||
          {}
      )
      .some((questionDefinition) =>
        shouldFieldBeRendered(questionDefinition, documentValue)
      )
    return anyVisible
  }
}

export function anyFieldHasValue(questionsArray, documentValue) {
  return (fields) => {
    return fields.some((field) => {
      const questionDefinition =
        questionsArray.find((question) => question.questionId === field.id) ||
        {}
      return shouldFieldBeRendered(questionDefinition, documentValue)
    })
  }
}

export function expandCustomFieldsIntoRootObject(fieldValue) {
  if(Array.isArray(fieldValue)) {
    return fieldValue.map((item) => {
      return { ...item, ...(item.customFields || {}) }
    })
  }else{
    return { ...fieldValue, ...(fieldValue.customFields || {}) }
  }
}
