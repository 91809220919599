<template>
  <aside v-on-clickaway="handleClickaway">
    <div class="max-w-screen-xl mx-auto px-4 relative">
      <div class="flex justify-between items-center lg:justify-start lg:space-x-10">
        <div class="lg:flex-1 flex">
          <slot name="logo">
            <BaseLogo />
          </slot>
        </div>
        <div class="-mr-2 -my-2 lg:hidden">
          <HamBurger
            class="color-theme-topbar-font hamburger"
            @click.native="toggleNav"
          />
        </div>
        <nav
          class="lg:py-6 responsive-nav transition ease-in-out duration-150 fixed h-full z-50 right-0 top-0 lg:static overflow-y-auto lg:overflow-y-visible"
          :class="{ 'open': isNavOpen, 'no-print': !isNavOpen }"
          aria-label="Mobile Navigation"
          data-test="openNav"
        >
          <div class="flex items-start mb-2 lg:hidden color-theme-topbar-font m-6">
            <h1 class="heading-1 flex-1 mr-2">{{ schoolDisplayName }}</h1>
            <button
              type="button"
              aria-label="Close"
              @click="toggleNav"
            >
              <BaseIcon icon="times" />
            </button>
          </div>
          <Navigation
            :links="sidebar"
            :active-link-index="activeLinkIndex"
            class="bottom-links block lg:flex lg:gap-10 lg:py-3"
            @dropdown="activeLinkIndex = $event"
          />
        </nav>
        <div
          v-if="isNavOpen"
          class="fixed right-0 top-0 h-full w-full z-40 lg:hidden"
          data-test="toggleNav"
          @click="toggleNav"
        />
      </div>
    </div>
  </aside>
</template>

<script>
import { mapState } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'
import HamBurger from './HamBurger'
import Navigation from './Navigation'
import BaseLogo from '@/components/contemporaryTheme/components/BaseLogo'

export default {
  components: {
    BaseLogo,
    HamBurger,
    Navigation,
  },
  mixins: [clickaway],
  data() {
    return {
      activeLinkIndex: null,
      isNavOpen: false,
    }
  },
  computed: {
    ...mapState('settings', ['sidebar', 'schoolDisplayName']),
  },
  beforeDestroy() {
    this.allowScroll()
  },
  methods: {
    toggleNav() {
      this.isNavOpen = !this.isNavOpen
      if (this.isNavOpen) this.disableScroll()
      else this.allowScroll()
    },
    allowScroll() {
      document.querySelector('body').classList.remove('disable-scroll')
      document.querySelector('html').classList.remove('disable-scroll')
    },
    disableScroll() {
      document.querySelector('body').classList.add('disable-scroll')
      document.querySelector('html').classList.add('disable-scroll')
    },
    handleClickaway() {
      this.activeLinkIndex = null
    },
  },
}
</script>

<style scoped lang="scss">
.bottom-links ::v-deep .link {
  text-align: left;
}
.bottom-links ::v-deep .nav-flat-link {
  // color: var(--sidebarElementsFontColor, var(--text-grey-500));
  // TODO: Temp hot fix, but it suppose to be --sidebarElementsFontColor
  color: var(--topbarElementsFontColor, #c6e25d) !important;
  font-family: var(--sidebarElementsFontTypeface, inherit);
  font-weight: var(--sidebarElementsFontWeight, 500);
}

.hamburger > path {
  stroke: var(--topbarElementsFontColor);
}

.responsive-nav {
  @media (max-width: 1023px) {
    & ::v-deep ul > li {
      margin: 1rem;
    }
    width: 300px;
    transform: translateX(300px);
    background-color: var(--theme-navbar-background);
    &.open {
      transform: translateX(0px);
      box-shadow: 0px 0px 100px rgb(0 0 0 / 50%);
      z-index: 100;
    }
  }
}
.heading-1 {
  @apply text-2xl mb-2;
  color: var(--topbarElementsFontColor, #c6e25d) !important;
  font-family: var(--sidebarElementsFontTypeface, inherit);
  font-weight: var(--h1HeaderFontWeight, 700);
}
@media print {
  .no-print {
    display: none !important;
  }
}
</style>
