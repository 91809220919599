<template>
  <NuxtLink
    v-if="hasLogo || hasSchoolName"
    to="/"
    class="flex items-center font-semibold text-xl tracking-tight logo-link mr-4"
  >
    <template v-if="hasLogo">
      <img
        :src="image"
        class="mr-4 img"
        :alt="schoolDisplayName"
      >
    </template>
    <template v-if="hasSchoolName">
      <span class="color-theme-topbar-font hover:opacity-80">Stanford Bulletin</span>
    </template>
  </NuxtLink>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('settings', ['schoolDisplayName', 'settings']),
    homeLinkType() {
      if (
        typeof this.settings.homeLinkType === 'object' &&
        this.settings?.homeLinkType?.value
      ) {
        return this.settings?.homeLinkType?.value
      }
      return this.settings.homeLinkType
    },
    hasLogo() {
      return this.homeLinkType === 'logotype' || this.homeLinkType === 'logo'
    },
    hasSchoolName() {
      return this.homeLinkType === 'logotype' || this.homeLinkType === 'text'
    },
    image() {
      return this.settings.navImage || require(`~/static/coursedog-logo.png`)
    },
  },
}
</script>

<style lang="css" scoped>
.img {
  max-height: 80px;
}
</style>
