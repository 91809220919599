<template>
  <div @click="$emit('toggle-dropdown')">
    <button
      type="button"
      class="group space-x-2"
      :aria-expanded="isOpen?'true':'false'"
    >
      <span class="sub-link">{{ item.label }}</span>
      <svg
        :class="[{'rotate-180': isOpen}, 'transform h-5 w-5 transition ease-in-out duration-150']"
        viewBox="0 0 20 20"
        aria-hidden="true"
        role="img"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
    <transition
      enter-active-class="transition ease-out duration-200"
      enter-class="opacity-0 translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-150"
      leave-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-1"
    >
      <div
        v-show="isOpen"
        class="relative md:absolute mt-3 -mr-4 transform sm:px-0 right-0 z-20"
      >
        <div class="rounded-lg shadow-lg">
          <div class="rounded-lg shadow-xs overflow-hidden">
            <div class="relative flex flex-row bg-white px-4 py-4">
              <div
                v-for="(column, indexC) in columns"
                :key="indexC"
                class="flex flex-col space-y-2"
              >
                <div
                  v-for="(link, index) in column"
                  :key="index"
                  class="drop-item"
                >
                  <template v-if="link.type === 'group'">
                    <NavSubGroup :list="link" />
                  </template>
                  <NavLink
                    v-else
                    :link="link"
                    :class="{'underline' : $route.path === link.url}"
                    class="-m-2 p-2 flex items-start space-x-4 rounded-lg hover:opacity-80 transition ease-in-out duration-150 item-link"
                  >
                    <div class="space-y-1">
                      <p
                        class="text-base leading-6"
                       >
                        {{ link.label }}
                      </p>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import NavLink from './NavLink'
import NavSubGroup from './NavSubGroup'

export default {
  components: {
    NavLink,
    NavSubGroup,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    columns() {
      return this.item.children.reduce(
        (acc, curr, idx) => {
          const currentColumn = acc[acc.length - 1]
          if (
            currentColumn.length &&
            currentColumn[currentColumn.length - 1].type === 'group'
          ) {
            acc.push([curr])
          } else {
            currentColumn.push(curr)
          }
          return acc
        },
        [[]]
      )
    },
  },
}
</script>
<style scoped>
.group {
  @apply inline-flex items-center text-base leading-6 font-medium transition ease-in-out duration-150;
  color: var(--topbarElementsFontColor, inherit);
}
.group:hover {
  @apply underline opacity-80;
}
.drop-item {
  min-width: 200px;
}
.sub-link,
.sub-link:hover,
.sub-link:focus {
  color: var(--topbarElementsFontColor, inherit);
  font-family: var(--topbarElementsFontTypeface, inherit);
  font-weight: var(--topbarElementsFontWeight, inherit);
}

.item-link {
  color: var(--interfaceFontColor, var(--text-gray-700));
  font-family: var(--sidebarElementsFontTypeface, inherit);
  font-weight: var(--sidebarElementsFontWeight, 500);
}

svg > path {
  fill: var(--topbarElementsFontColor);
}
</style>
