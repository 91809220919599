<template>
  <!-- This example requires Tailwind CSS v1.4.0+ -->
  <div class="relative bg-theme-navbar-background">
    <div
      class="w-full bg-theme-dark"
      data-test="primary-nav"
    >
      <div class="max-w-screen-xl mx-auto px-4 py-2 sm:px-6 text-white text-sm">
        <div class="flex items-center color-theme-navbar-link">
          <div class="flex-1">
            <slot />
          </div>
          <nav class="hidden md:flex space-x-10">
            <div
              v-for="(link, index) of navbar"
              :key="index"
              class="relative"
            >
              <NavLink
                v-if="link.type === 'link'"
                :link="link"
                class="top-link hover:opacity-70 hover:underline transition ease-in-out duration-150"
                :class="{'underline' : isActiveLink(link)}"
              >
                {{ link.label }}
              </NavLink>
            </div>
          </nav>
          <button
            v-if="enableGlobalSearch"
            type="button"
            class="ml-6"
            style="min-width:150px;"
            @click="showSearchModal = true"
          >
            <BaseIcon
              icon="search"
              class="mr-2"
            />
            Search . . .
          </button>
        </div>
      </div>
    </div>

    <div
      class="max-w-screen-xl mx-auto px-4"
      data-test="secondary-nav"
    >
      <div class="flex justify-between items-center py-6 md:justify-start md:space-x-10">
        <div class="lg:w-0 lg:flex-1">
          <BaseLogo class="h-12 w-auto sm:h-10" />
        </div>
        <div class="-mr-2 -my-2 md:hidden">
          <button
            aria-label="Navigation Menu"
            type="button"
            class="inline-flex items-center justify-center p-2 rounded-md color-theme-topbar-font hover:opacity-50 transition duration-150 ease-in-out mobile-nav-button"
            @click="toggleNav"
          >
            <svg
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <div
          v-if="isNavOpen"
          class="responsive-nav-overlay fixed right-0 top-0 h-full w-full z-40 md:hidden"
          @click="toggleNav"
        ></div>
        <nav
          class="responsive-nav transition ease-in-out duration-150 fixed h-full z-50 right-0 top-0 md:flex md:space-x-10 md:py-3 md:relative"
          :class="{'open':isNavOpen}"
          @mouseleave="openNavDrop = null"
        >
          <div
            v-for="(link, index) of sidebar"
            :key="index"
            class="relative"
          >
            <template v-if="link.type === 'group'">
              <NavDropdown
                :item="link"
                :is-open="openNavDrop === index"
                class="sub-link"
                :class="{'underline' : isActiveLink(link)}"
                @toggle-dropdown="toggleDropdown(index)"
              />
            </template>
            <NavLink
              v-else
              :link="link"
              class="bot-link text-base leading-6 my-2 hover:underline transition ease-in-out duration-150 color-theme-topbar-font hover:opacity-70"
              :class="{'underline' : isActiveLink(link)}"
            >
              {{ link.label }}
            </NavLink>
          </div>
        </nav>
      </div>
    </div>
    <SearchModal
      v-if="showSearchModal"
      @close="showSearchModal = false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NavDropdown from './NavDropdown'
import NavLink from './NavLink'
import BaseLogo from './BaseLogo'
import SearchModal from '@/components/SearchModal'
import useFeatureFlags from '@/composables/useFeatureFlags'
import { GLOBAL_SEARCH } from '@/constants/featureFlags'

export default {
  setup() {
    const { enableGlobalSearch } = useFeatureFlags({
      enableGlobalSearch: GLOBAL_SEARCH,
    })
    return { enableGlobalSearch }
  },
  components: {
    BaseLogo,
    NavDropdown,
    NavLink,
    SearchModal,
  },
  data() {
    return {
      isNavOpen: false,
      openNavDrop: null,
      showSearchModal: false,
    }
  },
  computed: {
    ...mapState('settings', ['sidebar', 'navbar']),
    currentPageFullPath() {
      return this.$route.fullPath
    },
  },
  watch: {
    $route () {
      this.isNavOpen = false
      this.openNavDrop = null
    },
    '$route.name' () {
      const root = document.querySelector('#main-content-skip-link').parentElement
      root.setAttribute('tabindex', '-1')
      root.focus()
      root.removeAttribute('tabindex')
    }
  },
  methods: {
    isActiveLink(link) {
      if (link.url === '/') {
        return this.currentPageFullPath === link.url
      }
      return this.currentPageFullPath.includes(link.url)
    },
    toggleNav() {
      this.isNavOpen = !this.isNavOpen
    },
    toggleDropdown(index) {
      this.openNavDrop = this.openNavDrop === index ? null : index
    },
  },
}
</script>
<style scoped lang="scss">
.top-link {
  font-family: var(--topbarElementsFontTypeface, inherit);
  font-weight: var(--topbarElementsFontWeight, inherit);
}

.bot-link {
  color: var(--sidebarElementsFontColor, var(--text-grey-500));
  font-family: var(--sidebarElementsFontTypeface, inherit);
  font-weight: var(--sidebarElementsFontWeight, 500);
}
.bot-link:hover,
.bot-link:focus {
  color: var(--sidebarElementsFontColor, var(--text-grey-900));
}

.mobile-nav-button > path {
  stroke: var(--topbarElementsFontColor);
}

.responsive-nav-overlay {
  background: rgba(0, 0, 0, 0.25);
}
.responsive-nav {
  @media (max-width: 767px) {
    & > div {
      margin: 1.5rem;
    }
    width: 300px;
    transform: translateX(300px);
    background-color: var(--theme-navbar-background);
    &.open {
      transform: translateX(0px);
      box-shadow: 0px 0px 100px #000;
    }
  }
}
</style>
