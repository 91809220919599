export const LogoBlock = ({ props }) => (
  <img
    class="md:h-32 w-auto inline-block ml-4 mr-6 mb-4"
    src={props.content}
    aria-hidden="true"
  />
)

export const RowBlock = {
  functional: true,
  render(h, { props }) {
    return (
      <div class="flex flex-col md:flex-row flex-grow">
        {props.content.map((el) => (
          <el.component content={el.content} />
        ))}
      </div>
    )
  },
  props: { content: Array },
}

export const NavBlock = {
  functional: true,
  render(h, { props }) {
    return (
      <div class="block mb-8 px-4 text-left w-full">
        <h2 class="text-xs foot-headers mb-2 md:h-4 block uppercase">
          {props.content.header}
        </h2>
        <ul>
          {props.content.links.map((link) => (
            <li>
              <a
                class="color-theme-light hover:underline text-sm mb-2 inline-block"
                href={link.url}
              >
                {link.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    )
  },
  props: { content: Object },
}

export const FreeFormBlock = {
  functional: true,
  render(h, { props }) {
    return h('div', {
      class: 'text-left block w-full px-4',
      domProps: {
        innerHTML: props.content,
      },
    })
  },
  props: { content: String },
}

export const NewBlock = () => <div />
