<template>
  <div class="bg-theme-dark">
    <div class="max-w-screen-xl mx-auto px-4 py-2 sm:px-6 text-white text-sm">
      <nav class="flex items-center color-theme-navbar-link">
        <div class="flex-1 mr-6">
          <slot name="top-nav-before" />
        </div>
        <Navigation
          :links="navbar"
          class="hidden md:flex space-x-5 top-links"
        />
        <button
          v-if="enableGlobalSearch"
          type="button"
          ref="searchButton"
          class="ml-6 px-3 py-2 rounded text-left"
          style="background: rgba(0,0,0,0.1);min-width:200px;"
          @click="showSearchModal = true"
        >
          <BaseIcon
            icon="search"
            class="mr-2"
          />
          Search . . .
        </button>
      </nav>
    </div>
    <SearchModal
      v-if="showSearchModal"
      @close="closeSearchModal"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Navigation from './Navigation'
import SearchModal from '@/components/SearchModal'
import useFeatureFlags from '@/composables/useFeatureFlags'
import { GLOBAL_SEARCH } from '@/constants/featureFlags'

export default {
  setup() {
    const { enableGlobalSearch } = useFeatureFlags({
      enableGlobalSearch: GLOBAL_SEARCH,
    })
    return { enableGlobalSearch }
  },
  components: {
    SearchModal,
    Navigation,
  },
  data() {
    return {
      showSearchModal: false,
    }
  },
  computed: {
    ...mapState('settings', ['navbar']),
  },
  methods: {
    closeSearchModal () {
      this.showSearchModal = false
      this.$nextTick(() => {
        this.$refs.searchButton.focus()
      })
    }
  }
}
</script>

<style scoped>
.top-links >>> a {
  font-family: var(--topbarElementsFontTypeface, inherit);
  font-weight: var(--topbarElementsFontWeight, inherit);
}
</style>
